body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 0 !important; /* 메시지와 아이콘 사이의 마진 제거 */
}

.ant-notification-notice-with-icon {
    padding-left: 48px; /* 아이콘과 텍스트 사이의 간격 조절 */
}
